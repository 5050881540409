import React from "react";

export const Regulation = (props) => {
  return (
    <div id="regulation" className="text-center">
      <div className="container">
        <div className="col-md-8 col-md-offset-2 section-title">
          <h2>EU Regulation</h2>
          <p>
            Regulation (EU) 2023/1542 aims to ensure that, in the future, batteries have a low carbon footprint and use minimal harmful substances.
          </p>
        </div>
        <div className="row">
        <div className="col-md-4 col-sm-6">
            <div className="progress green">
                <span className="progress-left">
                    <span className="progress-bar"></span>
                </span>
                <span className="progress-right">
                    <span className="progress-bar"></span>
                </span>
                <div className="progress-value">51%</div>
            </div>
            <h5>Waste collection objectives for Light Means of Tranport batteries by end of 2028</h5>
        </div>
        <div className="col-md-4 col-sm-6">
            <div className="progress yellow">
                <span className="progress-left">
                    <span className="progress-bar"></span>
                </span>
                <span className="progress-right">
                    <span className="progress-bar"></span>
                </span>
                <div className="progress-value">65%</div>
            </div>
            <h5>Lithium-based batteries - recycling efficiency targets by end of 2025</h5>
        </div>
        <div className="col-md-4 col-sm-6">
            <div className="progress pink">
                <span className="progress-left">
                    <span className="progress-bar"></span>
                </span>
                <span className="progress-right">
                    <span className="progress-bar"></span>
                </span>
                <div className="progress-value">50%</div>
            </div>
            <h5>Targets for lithium recovery from waste batteries by end of 2027</h5>
        </div>
    </div>
      </div>
    </div>
  );
};
