import React, { useState } from "react";
import ReactApexChart from "react-apexcharts";

export const Technology = (props) => {

  const [series] = useState([
    {
      name: 'Al Housing, Electronics',
      data: [23]
    },
    {
      name: 'Electrolyte',
      data: [5.4]
    },
    {
      name: 'Heavy Parts (Al, Cu, Fe)',
      data: [10.8]
    },
    {
      name: 'Metal Foils (Al, Cu)',
      data: [12.3]
    },
    {
      name: 'Plastics',
      data: [2.3]
    },
    {
      name: 'Black Mass',
      data: [46.2]
    }
  ]);

  const [options] = useState({
    colors:['#8d8f91', '#74c31e', '#ca9403', '#4462a9','#b20000','#414141'],
    chart: {
      type: 'bar',
      height: 250,
      stacked: true,
      stackType: "100%",
      toolbar: {
        tools: {
          download: false
        }
      }
    },
    plotOptions: {
      bar: {
        horizontal: true,
        dataLabels: {
          total: {
            enabled: true,
            offsetX: 0,
            style: {
              fontSize: '13px',
              fontWeight: 900
            }
          }
        }
      },
    },
    stroke: {
      width: 2,
      colors: ['#fefefe']
    },
    title: {
      text: 'Complete weight yield achieved through a low-temperature process.'
    },
    xaxis: {
      categories: [''],
      labels: {
        formatter: function (val) {
          return val + "%";
        }
      }
    },
    yaxis: {
      title: {
        text: undefined
      },
    },
    tooltip: {
      y: {
        formatter: function (val) {
          return val + "%";
        }
      }
    },
    fill: {
      opacity: 1 
    },
    legend: {
      position: 'top',
      horizontalAlign: 'center',
    }
  });

  return (
    <div id="technology">
      <div className="container">
        <div className="section-title text-center">
          <h2>Technology</h2>
          <p>100% Recycling of end-of-life Lithium-Ion Batteries.</p>
        </div>
    <div className="row">
        <div className="col-12">
    <div className="card text-justify">
      <div className="card-body">
        <div id="chart">
        <ReactApexChart options={options} series={series} type="bar" height={270} />
      </div>
      <div id="html-dist"></div>
      </div>
    </div>
    </div>
        <div className="col-sm-6 mb-3 mb-sm-0">
    <div className="card">
      <div className="card-body">
        <h5 className="card-title">Pre-treatment of Lithium-ion Batteries</h5>
        <p className="card-text">Following discharge and disassembly, the batteries are crushed in a controlled inert gas environment. The electrolyte solvent is extracted from the crushed materials through vacuum distillation.. The resulting solvent is of exceptionally high purity, as the formation of hydrogen fluoride from byproducts is circumvented. This solvent is then forwarded to the chemical industry for further refinement.</p>
        <p className="card-text">Additionally, the facility is capable of processing dry electrode waste generated during battery cell manufacturing. There is no requirement for retooling, and the shredded materials can proceed directly to the next phase of sorting without needing any drying period.</p>
      </div>
    </div>
    </div>
    <div className="col-sm-6 mb-3 mb-sm-0">
    <div className="card text-justify">
      <div className="card-body">
        <h5 className="card-title">Recycling of Al and Cu parts from Lithium-Ion Batteries</h5>
        <p className="card-text">Burg Machinery Ltd is a wholly owned subsidiary of Roster Ltd, specializing in the recycling of ferrous and non-ferrous metals. The recycling of aluminum and copper parts is carried out in the mixed metal recycling plant of Roster Ltd. The recycling plant is equipped with the latest technology, manufactured by a large Italian producer.</p>
        <p className="card-text">With proven purity levels of over 99.7% for both copper and aluminum, we have established numerous loyal partnerships and clients who trust us to recycle their metalcontaining waste and transform it into valuable resources that can be used to create new products.</p>
        <a href="https://www.youtube.com/watch?v=imJX2RJZEko&t=67s&ab_channel=PanizzoloRecycling" target="_blank" className="btn btn-T-custom btn-lg page-scroll pull-right">Learn More</a>
      </div>
    </div>
  </div>
  </div>
      </div>
    </div>
  );
};
